import Flickity from 'flickity';
import Base from '@studiometa/js-toolkit/abstracts/Base';
import { isDev } from '../config';

/**
 * CrosssellPosts class.
 */
export default class CrosssellPosts extends Base {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'CrosssellPosts',
      flkt: {
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        pageDots: false,
      },
    };
  }

  /**
   * Log a nice message when app is ready.
   *
   * @return {void}
   */
  mounted() {
    this.slider = new Flickity(this.$refs.slider, {
      ...this.$options.flkt,
      on: {
        ready: () => {
          this.$refs.slider.classList.add('is-flickity-ready');
        },
        dragStart: () => {
          this.$refs.slider.classList.add('pointer-events-none');
        },
        dragEnd: () => {
          this.$refs.slider.classList.remove('pointer-events-none');
        },
      },
    });
  }

  /**
   * Handle slider arrow previous click
   */
  onPrevClick() {
    this.slider.previous();
  }

  /**
   * Handle slider arrow next click
   */
  onNextClick() {
    this.slider.next();
  }

  /**
   * Destroyed
   */
  destroyed() {
    this.slider.destroy();
    this.slider = null;
    this.$refs.slider.classList.remove('is-flickity-ready');
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
