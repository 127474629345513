import Base from '@studiometa/js-toolkit/abstracts/Base';
import withBreakpointObserver from '@studiometa/js-toolkit/decorators/withBreakpointObserver';
import { TweenMax } from 'gsap';
// import matrix from '@studiometa/js-toolkit/utils/css/matrix';
import { isDev } from '../config';

/**
 * Cursor class.
 */
export default class Cursor extends withBreakpointObserver(Base) {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'Cursor',
      activeBreakpoints: 'l xl xxl',
      settings: {
        /** @type {Boolean} Has the cursor been initialized? */
        isInit: false,
        /** @type {Number} The cursor y position */
        y: window.innerHeight / 2,
        /** @type {Number} Default cursor scale */
        DEFAULT_SCALE: 0.5,
        /** @type {Number} Hover cursor scale */
        HOVER_SCALE: 1,
        /** @type {Array} List of selectors which must trigger a cursor hover state */
        HOVER_SELECTORS: [
          'a[href]:not(:disabled)',
          'a[href]:not(:disabled) *',
          'button:not(:disabled)',
          'button:not(:disabled) *',
          'textarea:not(:disabled)',
          'input[type="text"]:not(:disabled)',
          'input[type="email"]:not(:disabled)',
          'input[type="tel"]:not(:disabled)',
          '.field-switch__label',
          '.field-switch__label *',
          '.field-switch__fake-input',
          '.field-switch__fake-input *',
          '.filters-choice-button',
          '.filters-choice-button *',
          '.go-top',
          '.go-top *',
        ].join(', '),
        /** @type {String} Class added when the cursor is active */
        ACTIVE_CLASS: 'has-cursor',
      },
    };
  }

  /**
   * Mounted
   */
  mounted() {
    Object.assign(this, this.$options.settings);

    document.documentElement.classList.add('has-cursor');
  }

  /**
   * Get the current scale value
   *
   * @return {Number}
   */
  get scale() {
    return this.isHover ? this.HOVER_SCALE : this.DEFAULT_SCALE;
  }

  /**
   * Moved
   */
  moved({ x, y, event }) {
    this.x = x;
    this.y = y;
    this.isHover = event.target.matches(this.HOVER_SELECTORS);
  }

  /**
   * Ticked.
   */
  ticked() {
    TweenMax.to(this.$refs.pointer, 0.1, {
      x: this.x,
      y: this.y,
      scale: this.scale,
      ease: 'ease-out',
    });
  }

  /**
   * Destroy
   */
  destroyed() {
    document.documentElement.classList.remove('has-cursor');
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
