import Base from '@studiometa/js-toolkit';
import matrix from '@studiometa/js-toolkit/utils/css/matrix';

/**
 * Headline desktop component
 */
export default class HeadLineDesktop extends Base {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      name: 'HeadLineDesktop',
    };
  }

  /**
   * Init vars when mounted for the first time.
   */
  mounted() {
    if (!this.hasBeenMountedOnce) {
      this.hasBeenMountedOnce = true;
      // Variables
      this.imageCurrent = null;
      this.mouse = {
        x: 0,
        y: 0,
        scale: 0.8,
      };
      this.mouseMoveTimeout = null;
      this.isMouseViewport = false;
    }
  }

  /**
   * Hide images on destroy.
   */
  destroyed() {
    this.$refs.masks.forEach(($mask) => {
      $mask.classList.remove('is-active');
    });
  }

  /**
   * Handle mouse enter image
   */
  onMouseenter() {
    if (!Array.isArray(this.$refs.masks)) {
      return;
    }

    if (this.mouseMoveTimeout) {
      clearTimeout(this.mouseMoveTimeout);
      this.mouseMoveTimeout = null;
    }

    this.isMouseViewport = true;

    this.$refs.masks.forEach(($mask) => {
      $mask.classList.remove('absolute', 'top-1/2', 'left-1/2');
      $mask.classList.add('fixed');
    });
  }

  /**
   * Handle mouse down
   */
  onButtonsMousedown() {
    this.mouse.scale = 0.7;
  }

  /**
   * Handle mouse up
   */
  onButtonsMouseup() {
    this.mouse.scale = 0.8;
  }

  /**
   * Handle mouse leave image
   */
  onMouseleave() {
    if (!Array.isArray(this.$refs.masks)) {
      return;
    }

    this.mouseMoveTimeout = setTimeout(() => {
      clearTimeout(this.mouseMoveTimeout);
      this.mouseMoveTimeout = null;
      this.isMouseViewport = false;

      this.$refs.masks.forEach(($mask) => {
        $mask.classList.remove('fixed');
        $mask.classList.add('absolute', 'top-1/2', 'left-1/2');
        $mask.style.transform = '';
      });
    }, 350);
  }

  /**
   * Handle mouse enter image
   */
  onButtonsMouseenter(event, index) {
    if (!Array.isArray(this.$refs.masks)) {
      return;
    }

    this.imageCurrent = null;
    if (!this.$refs.masks[index]) {
      return;
    }

    const $mask = this.$refs.masks[index];

    this.imageCurrent = $mask;

    if ($mask.previousTimeout) {
      clearTimeout($mask.previousTimeout);
      $mask.previousTimeout = null;
      $mask.classList.remove('is-previous');
    }

    $mask.classList.add('is-active');
  }

  /**
   * Handle mouse leave image
   */
  onButtonsMouseleave() {
    const $mask = this.imageCurrent;

    if (!$mask) {
      return;
    }

    $mask.classList.remove('is-active');
    $mask.classList.add('is-previous');

    $mask.previousTimeout = setTimeout(() => {
      clearTimeout($mask.previousTimeout);
      $mask.previousTimeout = null;
      $mask.classList.remove('is-previous');
    }, 500);
  }

  /**
   * Moved.
   */
  moved({ x, y, event }) {
    this.isMouseViewport = this.$el.contains(event.target);
    this.mouse = {
      x,
      y,
      scale: this.mouse.scale,
    };
  }

  /**
   * Ticked.
   */
  ticked() {
    if (!this.isMouseViewport || !Array.isArray(this.$refs.masks)) {
      return;
    }

    this.$refs.masks.forEach(($mask, index) => {
      $mask.style.transform = matrix({
        translateX: this.mouse.x - this.$refs.images[index].width / 2,
        translateY: this.mouse.y - this.$refs.images[index].height / 2,
        scaleX: this.mouse.scale,
        scaleY: this.mouse.scale,
      });
    });
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
