import Base from '@studiometa/js-toolkit/abstracts/Base';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { isDev } from '../config';

gsap.registerPlugin(ScrollToPlugin);

/**
 * GoTop class.
 */
export default class GoTop extends Base {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'GoTop',
    };
  }

  /**
   * Scrolled
   */
  scrolled({ y }) {
    if (y > 200 && !this.$el.classList.contains('is-show')) {
      this.$el.classList.add('is-show');
    } else if (y <= 200 && this.$el.classList.contains('is-show')) {
      this.$el.classList.remove('is-show');
    }
  }

  /**
   * Return to top of the page !
   * @return {void}
   */
  onClick() {
    gsap.to(window, {
      duration: 1,
      scrollTo: 0,
      ease: 'Power3.easeOut',
    });
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
