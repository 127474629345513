import Base from '@studiometa/js-toolkit';

/**
 * HeadLineMobile component.
 */
export default class HeadLineMobile extends Base {
  /**
   * HeadLineMobile config.
   * @return {Object}
   */
  get config() {
    return {
      name: 'HeadLineMobile',
    };
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
