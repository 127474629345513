import Base from '@studiometa/js-toolkit/abstracts/Base';
import { gsap } from 'gsap';
import { isDev } from '../config';
import TopBar from './TopBar';

/**
 * Header class.
 */
export default class Header extends Base {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'Header',
      components: {
        TopBar,
      },
    };
  }

  /**
   * Log a nice message when app is ready.
   *
   * @return {void}
   */
  mounted() {
    this.isOpen = false;

    this.tl = gsap.timeline({ pause: true });

    this.tl.to(this.$refs.headerItem, {
      duration: 0.4,
      opacity: 1,
      y: 0,
      stagger: {
        each: 0.1,
        ease: 'Power3.out',
      },
    });

    this.forward();
  }

  /**
   * Toggle `header--transparent` class when the bloc "hero fullscreen" is present and if user is at the top.
   *
   * @param {Object} props
   *
   * @return {void}
   */
  scrolled(props) {
    if (this.$parent.$refs.hero) {
      if (props.y === 0) {
        this.$el.classList.add('header--transparent');
      } else if (props.y !== 0 && this.$el.classList.contains('header--transparent')) {
        this.$el.classList.remove('header--transparent');
      }
    }
  }

  /**
   * Display Header
   * @return {void}
   */
  forward() {
    this.tl.play();

    if (this.$parent.$refs.hero && window.scrollY === 0) {
      this.$el.classList.add('header--transparent');
    }
  }

  /**
   * Hide Header
   * @return {void}
   */
  reverse() {
    this.tl.reverse();

    this.$el.classList.remove('header--transparent');
  }

  /**
   * Event mobile button click
   */
  onButtonClick() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      document.documentElement.classList.add('is-menu-open');
    } else {
      document.documentElement.classList.remove('is-menu-open');
    }
  }

  /**
   * Event menu mobile overlay click
   */
  onMenuMobileOverlayClick() {
    this.isOpen = !this.isOpen;
    document.documentElement.classList.remove('is-menu-open');
  }

  /**
   * Destroyed
   */
  destroyed() {
    this.$el.classList.remove('header--transparent');
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
