import Base from '@studiometa/js-toolkit/abstracts/Base';
import withIntersectionObserver from '@studiometa/js-toolkit/decorators/withIntersectionObserver';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { isDev } from '../config';

/**
 * HeroFullscreen class.
 */
export default class HeroFullscreen extends withIntersectionObserver(Base) {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'HeroFullscreen',
    };
  }

  /**
   * Mounted
   *
   * @return {void}
   */
  mounted() {
    this.split();
    this.links = Array.from(this.$refs.content.querySelectorAll('a'));
    if (this.$refs.hoverImage && this.links && this.$refs.hoverImage.length === this.links.length) {
      this.links.forEach((link, index) => {
        link.addEventListener('mouseenter', () => {
          this.$refs.hoverImage[index].classList.remove('opacity-0');
        });
        link.addEventListener('mouseleave', () => {
          this.$refs.hoverImage[index].classList.add('opacity-0');
        });
      });
    }
  }

  /**
   * Destroyed
   *
   * @return {void}
   */
  destroyed() {
    this.links.forEach((link, index) => {
      link.removeEventListener('mouseenter', () => {
        this.$refs.hoverImage[index].classList.remove('opacity-0');
      });
      link.removeEventListener('mouseleave', () => {
        this.$refs.hoverImage[index].classList.add('opacity-0');
      });
    });
  }

  /**
   * Split chars and words of element
   * @return {void}
   */
  split() {
    // When navigating between pages, Highway stores the generated markup in cache,
    // so if the split the text multiple times, we end up with chars inside chars.
    // We look for existing `.hero-fullscreen__chars` elements before using the `SplitText`
    // function to update the markup.
    const existingChars = Array.from(this.$el.querySelectorAll('.hero-fullscreen__chars'));
    if (existingChars.length > 0) {
      this.chars = existingChars;
      return;
    }

    const mySplitText = new SplitText(this.$el, {
      type: 'chars, words',
      charsClass: 'hero-fullscreen__chars',
      wordsClass: 'hero-fullscreen__words',
    });
    this.chars = mySplitText.chars;
  }

  /**
   * Triggered when the root element of the component is intersecting.
   * @param  {Array<IntersectionObserverEntry>} entries
   */
  intersected([entry]) {
    if (entry.isIntersecting && !this.isVisible) {
      this.isVisible = true;
      this.animateIn();
      this.$observer.disconnect();
    }
  }

  /**
   * Animate hero fullscreen
   * @return {void}
   */
  animateIn() {
    const tl = gsap.timeline();
    tl.fromTo(
      this.chars,
      { y: '60px' },
      {
        duration: 0.5,
        y: '0',
        stagger: {
          each: 0.007,
        },
        ease: 'sine.out',
        onComplete: () => {
          this.$el.classList.add('hero-fullscreen--isvisible');
        },
      }
    );
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
