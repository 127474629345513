import ModularScroll from 'modularscroll';
import clamp from './clamp';

// eslint-disable-next-line import/no-mutable-exports
let scroll;

/**
 * Lazyload
 */
export function init() {
  if (scroll) {
    return scroll;
  }

  scroll = new ModularScroll({
    offset: clamp(window.innerWidth * 0.2, 100, 300),
  });

  return scroll;
}

/**
 * Update lazyload
 */
export function update() {
  if (!scroll) {
    init();
  }

  scroll.init();
}

export default scroll;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
