import Base from '@studiometa/js-toolkit/abstracts/Base';
import withIntersectionObserver from '@studiometa/js-toolkit/decorators/withIntersectionObserver';
import withBreakpointManager from '@studiometa/js-toolkit/decorators/withBreakpointManager';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { isDev } from '../../config';
import HeadLineMobile from './HeadLineMobile';
import HeadLineDesktop from './HeadLineDesktop';

/**
 * HeadLine class.
 */
export default class HeadLine extends withBreakpointManager(withIntersectionObserver(Base), [
  ['xxs xs s', HeadLineMobile],
  ['m l xl xxl', HeadLineDesktop],
]) {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'HeadLine',
    };
  }

  /**
   * Log a nice message when app is ready.
   *
   * @return {void}
   */
  mounted() {
    this.split();
  }

  /**
   * Split chars and words of element
   * @return {void}
   */
  split() {
    // When navigating between pages, Highway stores the generated markup in cache,
    // so if the split the text multiple times, we end up with chars inside chars.
    // We look for existing `.headline__chars` elements before using the `SplitText`
    // function to update the markup.
    const existingChars = Array.from(this.$el.querySelectorAll('.headline__chars'));
    if (existingChars.length > 0) {
      this.chars = existingChars;
      return;
    }

    const mySplitText = new SplitText(this.$el, {
      type: 'chars, words',
      charsClass: 'headline__chars',
      wordsClass: 'headline__words',
    });
    this.chars = mySplitText.chars;
  }

  /**
   * Triggered when the root element of the component is intersecting.
   * @param  {Array<IntersectionObserverEntry>} entries
   */
  intersected([entry]) {
    if (entry.isIntersecting && !this.isVisible) {
      this.isVisible = true;
      this.animateIn();
      this.$observer.disconnect();
    }
  }

  /**
   * Animate headline
   * @return {void}
   */
  animateIn() {
    const tl = gsap.timeline();
    this.$el.style.opacity = 1;
    tl.fromTo(
      this.chars,
      { y: '60px' },
      {
        duration: 0.5,
        y: '0',
        stagger: {
          each: 0.007,
        },
        ease: 'sine.out',
        onComplete: () => {
          this.$el.classList.add('headline--isvisible');
        },
      }
    );
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
