import Highway from '@dogstudio/highway/src/highway';
import nextFrame from '@studiometa/js-toolkit/utils/nextFrame';
import gsap from 'gsap';
import { getSiteConfig } from '../../config';

/**
 * Use highway Transition
 *
 * @param object app The app.
 */
export default function useTransition(app) {
  /**
   * Highway transition
   */
  return class Transition extends Highway.Transition {
    /**
     * In
     *
     * @param object param0 In params.
     */
    async in({ from, to, done }) {
      const overlay = app.$refs.transitionOverlay;
      const templateBgWhite = getSiteConfig('transition.template_bg_white') || [];
      const pageTemplateName = to.dataset.routerView || '';

      // Reset Scroll
      window.scroll(0, 0);

      // Remove Old View
      from.remove();

      if (templateBgWhite.includes(pageTemplateName)) {
        document.body.classList.add('is-bg-white');
      } else {
        document.body.classList.remove('is-bg-white');
      }

      const header = ((app.$children || {}).Header || [])[0] || null;
      header.forward();

      app.$update();

      await nextFrame();
      await nextFrame();

      gsap.to(overlay, {
        y: '100%',
        duration: 0.6,
        ease: 'power3.inOut',
        onComplete: () => {
          overlay.style.transform = '';
          overlay.classList.remove('is-slide-center');
          done();
        },
      });
    }

    /**
     * Out
     */
    out({ done }) {
      const overlay = app.$refs.transitionOverlay;
      document.body.classList.remove('is-bg-white');

      app.reinitHeader();

      gsap.fromTo(
        overlay,
        {
          y: '-100%',
        },
        {
          y: '0',
          duration: 0.6,
          ease: 'power3.inOut',
          onComplete: () => {
            app.$refs.loader.classList.add('is-animate');

            setTimeout(() => {
              app.$refs.loader.classList.remove('is-animate');
              overlay.style.transform = '';
              overlay.classList.add('is-slide-center');
              done();
            }, 1000);
          },
        }
      );
    }
  };
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
