import Highway from '@dogstudio/highway/src/highway';
import useTransition from './useTransition';
import { update as updateLazyload } from '../lazyload';
import { update as updateScroll } from '../scroll';

let instance;

export const linksSelector = 'a:not([target]):not([data-router-disabled])';

/**
 * Append lazyload
 */
export function append(HTMLDom) {
  if (!instance || !(HTMLDom instanceof HTMLElement)) {
    return;
  }

  instance.attach(HTMLDom.querySelectorAll(linksSelector));
}

/**
 * Use highway Core
 *
 * @param object app The app.
 */
export default function useHighway(app) {
  if (instance) {
    return instance;
  }

  /**
   * Highway Core
   */
  instance = new Highway.Core({
    transitions: {
      default: useTransition(app),
    },
  });

  instance.on('NAVIGATE_OUT', () => {
    instance.cache.clear();
    const header = app?.$children?.Header?.[0] ?? null;

    app.reinitHeader();

    if (!header) {
      return;
    }

    header.reverse();

    if (header.isOpen && header.$refs.button) {
      header.$refs.button.click();
    }
  });

  instance.on('NAVIGATE_END', () => {
    updateLazyload();
    updateScroll();
  });

  return instance;
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
