/**
 * Test if the current env is a dev env.
 *
 * @return {Boolean}
 */
export const isDev = () => !window.location.hostname.startsWith('www.');

/**
 * Get a value from the site configuration.
 *
 * Example:
 *
 * ```js
 * import { getSiteConfig } from './config';
 * const templateBgWhite = getSiteConfig('transition.template_bg_white');
 * ```
 *
 * @param  {String}    path The path of the property value to return
 * @return {any|undefined}  Returns undefined when no value is found
 */
export const getSiteConfig = (path) => {
  const config = window.site || {};

  if (!path) {
    return config;
  }

  const keys = path.split('.');
  let data = config;

  while (keys.length) {
    if (!data) {
      return undefined;
    }

    data = data[keys.shift()];
  }

  return data || undefined;
};

export default {
  isDev,
  getSiteConfig,
};


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
