import 'element-closest-polyfill';
import Base from '@studiometa/js-toolkit/abstracts/Base';
import Accordion from '@studiometa/js-toolkit/components/Accordion';
import { isDev } from './config';
import ArchiveLookbook from './components/ArchiveLookbook';
import CrosssellPosts from './components/CrosssellPosts';
import Cursor from './components/Cursor';
import GoTop from './components/GoTop';
import Header from './components/Header';
import HeadLine from './components/HeadLine';
import Hero from './components/Hero';
import HeroFullscreen from './components/HeroFullscreen';
import HeroImage from './components/HeroImage';
import Slider from './components/Slider';
import { init as initLazyload } from './utils/lazyload';
import { init as initScroll } from './utils/scroll';
import useHighway from './utils/highway';

/**
 * Main App class.
 */
class App extends Base {
  /**
   * App config.
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'App',
      components: {
        Accordion,
        ArchiveLookbook,
        CrosssellPosts,
        Cursor,
        HeroFullscreen,
        HeroImage,
        GoTop,
        Header,
        HeadLine,
        Hero,
        Slider,
        PhotoshpereWrapper: () =>
          import(/* webpackChunkName: "PhotoshpereWrapper" */ './components/PhotoshpereWrapper'),
      },
    };
  }

  /**
   * Log a nice message when app is ready.
   *
   * @return {void}
   */
  mounted() {
    // eslint-disable-next-line dot-notation
    window['console'].log(
      '%c Made with ❤ by Studio Meta %c https://www.studiometa.fr',
      'display: inline-block; background-color: #2b2e34; color: #ffffff; font-weight: 500; margin-top: 8px; margin-bottom: 8px; padding: 8px; text-decoration: none !important; border: 2px solid #2b2e34;',
      'display: inline-block; background-color: #ffff; color: #1f2126; font-weight: 500; margin-top: 8px; margin-bottom: 8px; padding: 8px; text-decoration: none !important; border: 2px solid #2b2e34;'
    );

    // Lazyload
    initLazyload();

    // Highway
    useHighway(this);

    // Scroll animation
    initScroll();
  }

  /**
   * Scrolled
   *
   * @param object event Scroll event.
   */
  scrolled({ y, last }) {
    if (y <= 0) {
      return;
    }

    if (y > last.y && !this.$el.classList.contains('is-scroll-bottom')) {
      this.$el.classList.add('is-scroll-bottom');
      this.$el.classList.remove('is-scroll-top');
    } else if (y < last.y && !this.$el.classList.contains('is-scroll-top')) {
      this.$el.classList.add('is-scroll-top');
      this.$el.classList.remove('is-scroll-bottom');
    }
  }

  /**
   * Reinit header behavior, utils when page transition
   * @return {void}
   */
  reinitHeader() {
    this.$el.classList.remove('is-scroll-bottom');
    this.$el.classList.remove('is-scroll-top');
  }
}

const app = new App(document.documentElement);
window.app = app;
export default app;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
