/**
 * Clamp
 *
 * @param int value The value.
 * @param int min The min value.
 * @param int max The max value.
 */
export default function clamp(value, min, max) {
  /* eslint-disable no-nested-ternary */
  return min < max
    ? value < min
      ? min
      : value > max
      ? max
      : value
    : value < max
    ? max
    : value > min
    ? min
    : value;
  /* eslint-enable no-nested-ternary */
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
