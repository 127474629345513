import axios from 'axios';
import Base from '@studiometa/js-toolkit/abstracts/Base';
import { isDev } from '../config';
import { append as appendHighway } from '../utils/highway/index';
import { update as updateLazyload } from '../utils/lazyload';
import { update as updateScroll } from '../utils/scroll';

/**
 * ArchiveLookbook class.
 */
export default class ArchiveLookbook extends Base {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'ArchiveLookbook',
    };
  }

  /**
   * Log a nice message when app is ready.
   *
   * @return {void}
   */
  mounted() {
    this.requestCancel = null;
  }

  /**
   * Get current filter block
   */
  getCurrentFilterBlock() {
    const formData = new FormData(this.$refs.form);
    const view = formData.get('view') || '';

    let $blockReturn = null;

    this.$refs.filterBlock.forEach(($block) => {
      if ($block.dataset.type === view) {
        $blockReturn = $block;
      }
    });

    return $blockReturn;
  }

  /**
   * Handle filter "view" change
   */
  onFilterViewChange() {
    const formData = new FormData(this.$refs.form);

    this.$refs.filterBlock.forEach(($block) => {
      if (formData.get('view') === $block.dataset.type) {
        $block.classList.add('flex');
        $block.classList.remove('hidden');
      } else {
        $block.classList.remove('flex');
        $block.classList.add('hidden');
      }
    });

    this.ajaxSearch();
  }

  /**
   * Handle filters input change
   */
  onFilterInputChange(event) {
    const currentInput = event.target || null;
    const currentType = event.target.dataset.type || '';
    const currentValue = event.target.value || '';

    if (!currentType) {
      return;
    }

    const formData = new FormData(this.$refs.form);
    const formDataValues = formData.getAll(`${currentType}[]`);

    if (formDataValues.includes(currentValue)) {
      this.$refs.filterInput.forEach(($input) => {
        const inputType = $input.dataset.type || '';

        if (inputType === currentType) {
          $input.checked = false;
          $input.parentElement.classList.remove('is-active');
        }
      });

      currentInput.checked = true;
      currentInput.parentElement.classList.add('is-active');
    } else {
      currentInput.parentElement.classList.remove('is-active');
    }

    const $block = this.getCurrentFilterBlock();

    if ($block) {
      if (formDataValues.length) {
        $block.classList.add('is-filtered');
      } else {
        $block.classList.remove('is-filtered');
      }
    }

    this.ajaxSearch();
  }

  /**
   * Handle submit form
   */
  onFormSubmit(event) {
    event.preventDefault();
    this.ajaxSearch();
    return false;
  }

  /**
   * Search in ajax
   */
  ajaxSearch() {
    const action = this.$refs.form.action || '';
    const formData = new FormData(this.$refs.form);

    if (!action) {
      return;
    }

    if (this.requestCancel) {
      this.requestCancel.cancel('Operation canceled due to new request.');
      this.requestCancel = null;
    }

    this.requestCancel = axios.CancelToken.source();

    // Change browser URL with current form data
    const url = new URL(window.location.href.replace('/page/2', ''));
    url.searchParams.set('view', formData.get('view') || '');
    url.searchParams.set('product', formData.get('product[]') || '');
    window.history.pushState('', 'archive lookbook', url.toString());

    formData.set('action', 'lookbook_filter');

    axios
      .post(action, formData, {
        cancelToken: this.requestCancel.token,
      })
      .then((response) => {
        if (!this.$refs.list || !response.data) {
          return;
        }

        const $html = document.createDocumentFragment();
        const $htmlWrapper = document.createElement('html');
        $htmlWrapper.innerHTML = response.data;
        $html.appendChild($htmlWrapper);
        const $divList = $html.querySelector('.js-list');

        if (!$divList) {
          return;
        }

        this.$refs.list.innerHTML = '';
        this.$refs.list.appendChild($divList);

        updateLazyload();
        updateScroll();
        appendHighway(this.$refs.list);
      })
      .finally(() => {
        this.requestCancel = null;
      });
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
