import Base from '@studiometa/js-toolkit/abstracts/Base';
import withIntersectionObserver from '@studiometa/js-toolkit/decorators/withIntersectionObserver';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { isDev } from '../config';

/**
 * Hero class.
 */
export default class Hero extends withIntersectionObserver(Base) {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'Hero',
    };
  }

  /**
   * Launch split elements
   *
   * @return {void}
   */
  mounted() {
    this.split();
  }

  /**
   * Split chars and words of element
   * @return {void}
   */
  split() {
    // When navigating between pages, Highway stores the generated markup in cache,
    // so if the split the text multiple times, we end up with chars inside chars.
    // We look for existing `.headline__chars` elements before using the `SplitText`
    // function to update the markup.
    const existingChars = Array.from(this.$el.querySelectorAll('.hero__chars'));
    if (existingChars.length > 0) {
      this.chars = existingChars;
      return;
    }

    const mySplitText = new SplitText(this.$refs.heroTitle, {
      type: 'chars, words',
      charsClass: 'hero__chars',
      wordsClass: 'hero__words',
    });
    this.chars = mySplitText.chars;
    this.$el.style.opacity = 1;
  }

  /**
   * Triggered when the root element of the component is intersecting.
   * @param  {Array<IntersectionObserverEntry>} entries
   */
  intersected([entry]) {
    if (entry.isIntersecting && !this.isVisible) {
      this.isVisible = true;
      this.animateIn();
      this.$observer.disconnect();
    }
  }

  /**
   * Animate headline
   * @return {void}
   */
  animateIn() {
    const tl = gsap.timeline();
    tl.fromTo(
      this.chars,
      { y: '60px' },
      {
        duration: 1,
        y: '0',
        stagger: {
          each: 0.015,
          ease: 'sine.out',
        },
        ease: 'back.out(0.6)',
      }
    );
    tl.fromTo(this.$refs.heroContent, 1.2, { opacity: 0, y: '30px' }, { opacity: 1, y: 0 }, '-=1');
  }

  /**
   * Handle button click
   */
  onButtonClick() {
    const documentBoundingClientRect = document.documentElement.getBoundingClientRect();
    const elBoundingClientRect = this.$el.getBoundingClientRect();

    gsap.to(document.documentElement, {
      scrollTop:
        elBoundingClientRect.y - documentBoundingClientRect.y + elBoundingClientRect.height - 52,
      duration: 0.5,
      ease: 'circ.inOut',
    });
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
