import Base from '@studiometa/js-toolkit/abstracts/Base';
import { isDev } from '../config';

/**
 * TopBar class.
 */
export default class TopBar extends Base {
  /**
   * Class config
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'TopBar',
    };
  }

  /**
   * Mouted
   */
  mounted() {
    this.addMargin();
  }

  /**
   * Resized
   */
  resized() {
    this.addMargin();
  }

  /**
   * Add margin to the HTML element and to the hero fullscreen if is present.
   *
   * @return {void}
   */
  addMargin() {
    const height = this.$parent.$refs.wrapper.clientHeight;
    const { pageView } = this.$parent.$parent.$refs;

    if (pageView) {
      pageView.style.paddingTop = `${height}px`;
    }

    const { menuMobile } = this.$parent.$refs;

    if (menuMobile) {
      menuMobile.style.paddingTop = `${height}px`;
    }
  }

  /**
   * Destroyed
   */
  destroyed() {
    const { pageView } = this.$parent.$parent.$refs;

    if (pageView) {
      pageView.style.paddingTop = '';
    }

    const { menuMobile } = this.$parent.$refs;

    if (menuMobile) {
      menuMobile.style.paddingTop = '';
    }
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
